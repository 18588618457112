
import {defineComponent, ref} from "vue";
import useModel from "@/hooks/useModel";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    commentModalVisible: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const visibleModel = useModel(props, "visible");
    const commentModalVisibleModel = useModel(props, 'commentModalVisible')
    const comment = ref('');
    const handleCloseReturnVisible = ()=>{
      commentModalVisibleModel.value = false
      comment.value = ''
    }
    const handleCancel = () => {
      visibleModel.value = false;
    };
    const handleConfirm = () => {
      context.emit('on-confirm', comment.value)
      visibleModel.value = false;
    }


    return {
      commentModalVisibleModel,
      comment,
      handleCloseReturnVisible,
      visibleModel,
      handleCancel,
      handleConfirm
    };
  },
});
